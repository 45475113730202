/* Soluto's TextFieldLabel hover & focus override. As filed as defect: BG-14553 */
.microui-textfield-label {
    color: #6e767d !important;
}

.paypal-buttons-context-iframe {
    z-index: 0 !important;
}

div[type='text'] > input[type='text']:hover:not(.is-disabled) .microui-textfield-label,
div[type='text'] > input[type='text']:active:not(.is-disabled) .microui-textfield-label,
div[type='text'] > input[type='text']:focus:not(.is-disabled) .microui-textfield-label {
    color: #6e767d !important;
}
